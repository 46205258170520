import {defineStore} from 'pinia'

export const useStore = defineStore("mainStore", {
    state: () => {
        return {
            name: '',
            key: '',
            customerId: '',
            partnerSelect: '',
            agentAccountData: [],
            bankWithdrawList: [],
            agentList: [],
            agentSelect: '',
            role: ["bo", "call", "opt"],
            selectCustomer: '',
            listBankAccountBalance: [],
            reportWinLoseCustomer: {
                startDate: '',
                endDate: '',
                customerId: '',
                mode : ''
            }
        }
    },
    actions: {
        setKey(name) {
            this.key = name
        },
        setPartnerSelect(name) {
            this.partnerSelect = name
        },
        setAgentAccountData(data) {
            this.agentAccountData = data
        },
        setBankWithdrawList(data) {
            this.bankWithdrawList = data
        },
        setCustomerId(data) {
            this.customerId = data
        },
        setAgentListData(data) {
            this.agentSelect = data[0]
            this.agentList = data
        },
        setAgentSelect(data) {
            this.agentSelect = data
        },
        setRole(data) {
            this.role = data
        },
        setSelectCustomer(data) {
            this.selectCustomer = data
        },
        setListBankAccountBalance(data) {
            this.listBankAccountBalance = data;
        },
        setReportWinLoseCustomerSetStartDate(data) {
            this.reportWinLoseCustomer.startDate = data
        },
        setReportWinLoseCustomerSetEndDate(data) {
            this.reportWinLoseCustomer.endDate = data
        },
        setReportWinLoseCustomerSetCustomerId(data) {
            this.reportWinLoseCustomer.customerId = data
        },
        setReportWinLoseCustomerSetMode(data) {
            this.reportWinLoseCustomer.mode = data
        }
    }
})

