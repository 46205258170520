// import store from "@/state/store";

import { ui_setting } from "@/router/ui_setting";

export default [
  {
    path: "/google-auth",
    name: "googleAuth",
    meta: {
      title: "googleAuth",
      authRequired: false,
    },
    component: () => import("../views/googleAuth/bindTwoFactor.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      // beforeResolve(routeTo, routeFrom, next) {
      //     // If the user is already logged in
      //     if (store.getters["auth/loggedIn"]) {
      //         // Redirect to the home page instead
      //         next({ name: "default" });
      //     } else {
      //         // Continue to the login page
      //         next();
      //     }
      // },
    },
  },
  {
    path: "/gameList",
    name: "gameList",
    meta: {
      title: "gameList",
      authRequired: false,
    },
    component: () => import("../views/game/gameSettings/game.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      title: "dashboard",
      authRequired: false,
    },
    component: () => import("../views/Dashboard/dashboard.vue"),
  },
  {
    path: "/gameProviderList",
    name: "gameProviderList",
    meta: {
      title: "gameProviderList",
      authRequired: false,
    },
    component: () => import("../views/game/gameProvider/gameProvider.vue"),
  },
  {
    path: "/winlossReportAgent",
    name: "winlossReportAgent",
    meta: {
      title: "winlossReportAgent",
      authRequired: false,
    },
    component: () => import("../views/reports/winlossReport/agent.vue"),
  },
  {
    path: "/",
    name: "default",
    meta: {
      title: "default",
      authRequired: false,
    },
    component: () => import("../views/CallCenter/customer/Customer.vue"),
  },
  {
    path: "/agentCustomer",
    name: "AgentCustomer",
    meta: {
      title: "AgentCustomer",
      authRequired: false,
    },
    component: () => import("../views/reports/winlossReportCustomer/winlossReportCustomer.vue"),
  },
  {
    path: "/gameHistory",
    name: "gameHistory",
    meta: {
      title: "gameHistory",
      authRequired: false,
    },
    component: () => import("../views/reports/winlossReportCustomer/gameHistory/GameHistory.vue"),
  },
  {
    path: "/GameModeSelection",
    name: "GameModeSelection",
    meta: {
      title: "GameModeSelection",
      authRequired: false,
    },
    component: () => import("../views/reports/winlossReportCustomer/gameMode/GameModeSelection.vue"),
  },
  {
    path: "/customerInOutGame",
    name: "CustomerInOutGame",
    meta: {
      title: "CustomerInOutGame",
      authRequired: false,
    },
    component: () => import("../views/reports/customerInOutGame/customerInOutGame.vue"),
  },
  {
    path: "/customerPlayHistory",
    name: "customerPlayHistory",
    meta: {
      title: "CustomerInOutGame",
      authRequired: false,
    },
    component: () => import("../views/reports/customerPlayHistory/customerPlayHistory.vue"),
  },
  {
    path: "/winlossReportGameProvider",
    name: "gameProvider",
    meta: {
      title: "gameProvider",
      authRequired: false,
    },
    component: () => import("../views/reports/gameProvider/gameProvider.vue"),
  },
  {
    path: "/customerList",
    name: "customerList",
    meta: {
      title: "customerList",
      authRequired: false,
    },
    component: () => import("../views/customer/customerList/customerList.vue"),
  },
  {
    path: "/customerRefund",
    name: "customerRefund",
    meta: {
      title: "customerRefund",
      authRequired: false,
    },
    component: () => import("../views/reports/customerRefund/customerRefund.vue"),
  },
  {
    path: "/bankTransaction",
    name: "bankTransaction",
    meta: {
      title: "bankTransaction",
      authRequired: false,
    },
    component: () => import("../views/reports/bankTransaction/bankTransaction.vue"),
  },
  {
    path: "/bankTransactionAll",
    name: "bankTransactionAll",
    meta: {
      title: "bankTransactionAll",
      authRequired: false,
    },
    component: () => import("../views/CallCenter/bankTransactionAll/bankTransactionAll.vue"),
  },
  {
    path: "/winlossReportCustomer",
    name: "winlossReportCustomer",
    meta: {
      title: "winlossReportCustomer",
      authRequired: false,
    },
    component: () => import("../views/reports/winlossReportCustomerAndBank/winlossReportCustomerAndBank.vue"),
  },

  {
    path: "/dealerBankTransaction",
    name: "dealerBankTransaction",
    meta: {
      title: "dealerBankTransaction",
      authRequired: false,
    },
    component: () => import("../views/Dealer/bankTransaction/dealerBankTransaction.vue"),
  },
  {
    path: "/dealerPromo",
    name: "dealerPromo",
    meta: {
      title: "dealerPromo",
      authRequired: false,
    },
    component: () => import("../views/Dealer/dealerPromo/dealerPromo.vue"),
  },
  {
    path: "/dealerPromo/detail/:customerId",
    name: "dealerPromoDetail",
    meta: {
      title: "dealerPromoDetail",
      authRequired: false,
    },
    component: () => import("../views/Dealer/dealerPromo/dealerPromoDetail.vue"),
  },
  {
    path: "/bankTransaction/:customerId",
    name: "bankTransaction",
    meta: {
      title: "bankTransactionById",
      authRequired: false,
    },
    component: () => import("../views/Dealer/bankTransactionById/dealerBankTransactionById.vue"),
  },
  {
    path: "/bank",
    name: "bank",
    meta: {
      title: "bankList",
      authRequired: false,
    },
    component: () => import("../views/bank/bankList/BankList.vue"),
  },
  {
    path: "/bank/list",
    name: "bankList",
    meta: {
      title: "bankList",
      authRequired: false,
    },
    component: () => import("../views/bank/bankList/BankList.vue"),
  },
  {
    path: "/bank/createAgentBank",
    name: "createBank",
    meta: {
      title: "createBank",
      authRequired: false,
    },
    component: () => import("../views/bank/createBank/CreateBank.vue"),
  },
  {
    path: "/bank/agentTransfer",
    name: "agentTransfer",
    meta: {
      title: "createBank",
      authRequired: false,
    },
    component: () => import("../views/bank/agentTransfer/AgentTransfer.vue"),
  },

  {
    path: "/bank/financialReportOld",
    name: "financialReportOld",
    meta: {
      title: "financialReportOld",
      authRequired: false,
    },
    component: () => import("../views/bank/financialReport/FinancialReportSummaryBackupOldVersion.vue"),
  },
  {
    path: "/bank/financialReport",
    name: "financialReport",
    meta: {
      title: "financialReport",
      authRequired: false,
    },
    component: () => import("../views/bank/financialReport/FinancialReportSummary.vue"),
  },
  {
    path: "/bank/financialReportDetail",
    name: "financialReportDetail",
    meta: {
      title: "financialReportDetail",
      authRequired: false,
    },
    component: () => import("../views/bank/financialReport/FinancialReportSummaryDetail.vue"),
  },
  {
    path: "/bank/financialReport/create",
    name: "financialReportCreate",
    meta: {
      title: "financialReportCreate",
      authRequired: false,
    },
    component: () => import("../views/bank/financialReport/CreateFinancialReport.vue"),
  },


  {
    path: "/agentBankFavorite/list",
    name: "agentBankFavoriteList",
    meta: {
      title: "Agent Bank Favorite",
      authRequired: false,
    },
    component: () => import("../views/agentBankFavorite/AgentBankFavoritePage.vue"),
  },
  {
      path: "/agentBankFavorite/create",
      name: "agentBankFavoriteCreate",
      meta: {
          title: "Agent Bank Favorite",
          authRequired: false,
      },
      component: () => import("../views/agentBankFavorite/createAgentBankFavorite/CreateAgentBankFavorite.vue"),
  },


  {
    path: "/agentTxnTransfer/list",
    name: "agentTxnTransferList",
    meta: {
      title: "Agent Txn Transfer List",
      authRequired: false,
    },
    component: () => import("../views/agentTxnTransfer/AgentTxnTransferList.vue"),
  },
  {
    path: "/agentTxnTransfer/detail",
    name: "agentTxnTransferDetail",
    meta: {
      title: "Agent Txn Transfer Detail",
      authRequired: false,
    },
    component: () => import("../views/agentTxnTransfer/AgentTxnTransferDetailSlip.vue"),
  },


  {
    path: "/dealerReport",
    name: "dealerReport",
    meta: {
      title: "dealerReport",
      authRequired: false,
    },
    component: () => import("../views/reports/dealerReport/dealerReport.vue"),
  },
  {
    path: "/reportCustomerPromo",
    name: "reportCustomerPromo",
    meta: {
      title: "reportCustomerPromo",
      authRequired: false,
    },
    component: () => import("../views/BO/ReportCustomerPromo/ReportCustomerPromo.vue"),
  },
  {
    path: "/promoList",
    name: "promoList",
    meta: {
      title: "dealerReport",
      authRequired: false,
    },
    component: () => import("../views/promo/promoList/promoList.vue"),
  },
  {
    path: "/promoTransactions",
    name: "promoTransactions",
    meta: {
      title: "promoTransactions",
      authRequired: false,
    },
    component: () => import("../views/promo/promoTransactions/promoTransactionsList.vue"),
  },
  {
    path: "/promoReport",
    name: "promoReport",
    meta: {
      title: "dealerReport",
      authRequired: false,
    },
    component: () => import("../views/promoReport/PromoReportList/PromoReportList.vue"),
  },
  {
    path: "/promoReportDetail",
    name: "promoReportDetail",
    meta: {
      title: "dealerReport",
      authRequired: false,
    },
    component: () => import("../views/promoReport/PromoReportDetail/PromoReportDetail.vue"),
  },
  {
    path: "/customerNew",
    name: "customerNew",
    meta: {
      title: "customerNew",
      authRequired: false,
    },
    component: () => import("../views/customer/customerNew/customerNew.vue"),
  },
  {
    path: "/customerLogin",
    name: "customerLogin",
    meta: {
      title: "customerLogin",
      authRequired: false,
    },
    component: () => import("../views/customer/customerLogin/customerLogin.vue"),
  },

  {
    path: "/customer",
    name: "customer",
    meta: {
      title: "customer",
      authRequired: false,
    },
    component: () => import("../views/CallCenter/customer/Customer.vue"),
  },
  {
    path: "/depositWithdrawUnknown",
    name: "depositWithdrawUnknown",
    meta: {
      title: "depositWithdrawUnknown",
      authRequired: false,
    },
    component: () => import("../views/CallCenter/depositWithdrawUnknown/DepositWithdrawUnknown.vue"),
  },
  {
    path: "/depositNoPro",
    name: "depositNoPro",
    meta: {
      title: "depositNoPro",
      authRequired: false,
    },
    component: () => import("../views/CallCenter/depositNoPro/DepositNoPro.vue"),
  },
  {
    path: "/issueDeposit",
    name: "issueDeposit",
    meta: {
      title: "issueDeposit",
      authRequired: false,
    },
    component: () => import("../views/CallCenter/issueDeposit/IssueDeposit.vue"),
  },
  {
    path: "/customerOtp",
    name: "customerOtp",
    meta: {
      title: "customerOtp",
      authRequired: false,
    },
    component: () => import("../views/CallCenter/customerOtp/CustomerOtp.vue"),
  },
  {
    path: "/opt/slip",
    name: "OPTSlip",
    meta: {
      title: "OPTSlip",
      authRequired: false,
    },
    component: () => import("../views/OPT/slip/slip.vue"),
  },
  {
    path: "/opt/log",
    name: "OPTLog",
    meta: {
      title: "OPTLog",
      authRequired: false,
    },
    component: () => import("../views/OPT/logAdmin/logAdmin.vue"),
  },
  {
    path: "/log/customerReward",
    name: "customerReward",
    meta: {
      title: "customerReward",
      authRequired: false,
    },
    component: () => import("../views/log/customerReward/customerReward.vue"),
  },
  {
    path: "/opt/bankStatement/error",
    name: "bankStatementError",
    meta: {
      title: "optBankStatementError",
      authRequired: false,
    },
    component: () => import("../views/OPT/bankStatement/Error/bankStatement.vue"),
  },
  {
    path: "/opt/bankStatement/reject",
    name: "bankStatementReject",
    meta: {
      title: "bankStatementReject",
      authRequired: false,
    },
    component: () => import("../views/OPT/bankStatement/Reject/bankStatement.vue"),
  },
  {
    path: "/opt/bankStatement/max",
    name: "bankStatementMax",
    meta: {
      title: "bankStatementMax",
      authRequired: false,
    },
    component: () => import("../views/OPT/bankStatement/Max/bankStatement.vue"),
  },
  {
    path: "/opt/bankStatement/manual",
    name: "bankStatementManual",
    meta: {
      title: "bankStatementManual",
      authRequired: false,
    },
    component: () => import("../views/OPT/bankStatement/Manual/bankStatement.vue"),
  },
  {
    path: "/opt/withdrawMax",
    name: "withdrawMax",
    meta: {
      title: "withdrawMax",
      authRequired: false,
    },
    component: () => import("../views/OPT/bankStatement/WithdrawMax/WithdrawMax.vue"),
  },
  {
    path: "/opt/bank/transaction",
    name: "optBankTransaction",
    meta: {
      title: "optBankTransaction",
      authRequired: false,
    },
    component: () => import("../views/OPT/bankTransaction/BankTransaction.vue"),
  },
  {
    path: "/referralReport",
    name: "referralReport",
    meta: {
      title: "referralReport",
      authRequired: false,
    },
    component: () => import("../views/reports/referralReport/referralReport.vue"),
  },
  {
    path: "/reportOverView",
    name: "reportOverView",
    meta: {
      title: "reportOverView",
      authRequired: false,
    },
    component: () => import("../views/reports/reportOverView/reportOverView.vue"),
  },
  {
    path: "/creditTransactionCustom.vue",
    name: "creditTransactionCustom",
    meta: {
      title: "creditTransactionCustom.vue",
      authRequired: false,
    },
    component: () => import("../views/reports/creditTransactionCustom/creditTransactionCustom.vue"),
  },
  {
    path: "/settingPromotion",
    name: "settingPromotion",
    meta: {
      title: "settingPromotion",
      authRequired: false,
    },
    component: () => import("../views/BO/SettingPromotion/SettingPromotion.vue"),
  },
  {
    path: "/dealer",
    name: "dealer",
    meta: {
      title: "dealer",
      authRequired: false,
    },
    component: () => import("../views/Dealer/dealer/dealer.vue"),
  },
  {
    path: "/dealerWinlose",
    name: "dealerWinlose",
    meta: {
      title: "dealer",
      authRequired: false,
    },
    component: () => import("../views/Dealer/dealerWinlose/dealerWinloseMain.vue"),
  },
  {
    path: "/dealer/dealerWinlose/:customerId",
    name: "dealerWinloseByCustomerId",
    meta: {
      title: "dealer",
      authRequired: false,
    },
    component: () => import("../views/Dealer/dealerWinLossById/dealerWinloseByCustomerId.vue"),
  },
  {
    path: "/dealer/game/transaction/:customerId",
    name: "dealerGameTransactionById",
    meta: {
      title: "dealerGameTransactionById",
      authRequired: false,
    },
    component: () => import("../views/Dealer/dealerGameTransactionById/dealerGameTransactionById.vue"),
  },
  {
    path: "/dealerIncomeMain",
    name: "dealerIncomeMain",
    meta: {
      title: "dealer",
      authRequired: false,
    },
    component: () => import("../views/Dealer/dealerIncome/dealerIncomeMain.vue"),
  },
  {
    path: "/bank/settingDepositWithdraw",
    name: "settingDepositWithdraw",
    meta: {
      title: "settingDepositWithdraw",
      authRequired: false,
    },
    component: () => import("../views/bank/SettingDepositWithdraw/SettingDepositWithdraw.vue"),
  },
  {
    path: "/agent/settingAgent",
    name: "settingAgent",
    meta: {
      title: "settingAgent",
      authRequired: false,
    },
    component: () => import("../views/BO/SettingAgent/SettingAgent/SettingAgent.vue"),
  },
  {
    path: "/tournaments",
    name: "tournaments",
    meta: {
      title: "tournaments",
      authRequired: false,
    },
    component: () => import("../views/OPT/tournaments/Tournament.vue"),
  },
  {
    path: "/content",
    name: "content",
    meta: {
      title: "content",
      authRequired: false,
    },
    component: () => import("../views/BO/Content/Card/Content.vue"),
  },
  {
    path: "/notify",
    name: "notify",
    meta: {
      title: "notify",
      authRequired: false,
    },
    component: () => import("../views/BO/Content/Notify/Notify.vue"),
  },
  {
    path: "/crmAddPromo",
    name: "crmAddPromo",
    meta: {
      title: "crmAddPromo",
      authRequired: false,
    },
    component: () => import("../views/BO/CrmAddPromo/CrmAddPromo.vue"),
  },
  {
    path: "/settingPtAgent",
    name: "settingPtAgent",
    meta: {
      title: "settingPtAgent",
      authRequired: false,
    },
    component: () => import("../views/BO/SettingAgent/SettingPtAgent/SettingPtAgent.vue"),
  },
  {
    path: "/settingGameProvider",
    name: "settingGameProvider",
    meta: {
      title: "settingGameProvider",
      authRequired: false,
    },
    component: () => import("../views/BO/SettingGameProvider/SettingGameProvider.vue"),
  },
  {
    path: "/listSettingGame",
    name: "listSettingGame",
    meta: {
      title: "listSettingGame",
      authRequired: false,
    },
    component: () => import("../views/BO/SettingGameProvider/SettingGameDetail/SettingGameDetail.vue"),
  },
  {
    path: "/adminUser",
    name: "adminUser",
    meta: {
      title: "adminUser",
      authRequired: false,
    },
    component: () => import("../views/BO/AdminUser/AdminUser.vue"),
  },
  {
    path: "/c",
    name: "c",
    meta: {
      title: "c",
      authRequired: false,
    },
    component: () => import("../views/shortLink/c.vue"),
  },
  {
    path: "/gameCreate",
    name: "gameCreate",
    meta: {
      title: "gameCreate",
      authRequired: false,
    },
    component: () => import("../views/game/gameNews/index.vue"),
  },
  {
    path: "/reportDepositWithdraw",
    name: "reportDepositWithdraw",
    meta: {
      title: "reportDepositWithdraw",
      authRequired: false,
    },
    component: () => import("../views/BO/ReportDepositWithdraw/ReportDepositWithdraw.vue"),
  },
  {
    path: "/bank/QrReport",
    name: "QrReport",
    meta: {
      title: "QrReport",
      authRequired: false,
    },
    component: () => import("../views/BO/QrReport/QrReport.vue"),
  },

  ...ui_setting,
];
