<template>
   <router-view></router-view>
</template>

<script setup>
import {useStore} from "@/store";
import {onMounted, watch} from "vue";
const mainStore = useStore();

onMounted( () => {
  document.title = `Admin Agent - ${mainStore.agentSelect}`
})

watch(()=> mainStore.agentSelect, ()=> {
  document.title = `Admin Agent - ${mainStore.agentSelect}`
})

</script>