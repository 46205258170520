export const ui_setting = [
    {
        path: "/menu-setting",
        name: "menu-setting",
        meta: {
            title: "Auth Setting",
            authRequired: false,
        },
        component: () => import("../views/BO/UiSetting/auth-setting.vue"),
    },
    {
        path: "/wallet-setting",
        name: "wallet-setting",
        meta: {
            title: "Wallet Setting",
            authRequired: false,
        },
        component: () => import("../views/BO/UiSetting/wallet-setting.vue"),
    },
    {
        path: "/promotion-setting",
        name: "promotion-setting",
        meta: {
            title: "Promotion Setting",
            authRequired: false,
        },
        component: () => import("../views/BO/UiSetting/promotion-setting.vue"),
    },
    {
        path: "/mission-setting",
        name: "mission-setting",
        meta: {
            title: "Mission Setting",
            authRequired: false,
        },
        component: () => import("../views/BO/UiSetting/mission-setting.vue"),
    },
    {
        path: "/gacha-setting",
        name: "gacha-setting",
        meta: {
            title: "Gacha Setting",
            authRequired: false,
        },
        component: () => import("../views/BO/UiSetting/gacha-setting.vue"),
    },
    {
        path: "/diamond-setting",
        name: "diamond-setting",
        meta: {
            title: "Diamond Setting",
            authRequired: false,
        },
        component: () => import("../views/BO/UiSetting/diamond-setting.vue"),
    },
    {
        path: "/tournament-setting",
        name: "tournament-setting",
        meta: {
            title: "Tournament Setting",
            authRequired: false,
        },
        component: () => import("../views/BO/UiSetting/tournament-setting.vue"),
    },
]
